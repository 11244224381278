import { onMounted, defineComponent, reactive, toRefs } from 'vue';
import { getTransDetail } from '@/api/wallet';
export default defineComponent({
  name: 'TransDetail',

  setup() {
    const state = reactive({
      list: [],
      isComplete: false
    });

    const fetchData = async () => {
      const {
        data
      } = await getTransDetail();
      state.list = data;
      state.isComplete = true;
    };

    onMounted(() => {
      fetchData();
    });
    return { ...toRefs(state)
    };
  }

});